<template>
    <div class="landing-page">
      <div class="background-image"></div>
      <div class="content">
        <h1><div class="orange-text">Upgrade My Pitch Deck</div> <div class="orange-text">est ton outil pour perfectionner ta présentation.</div></h1>
        <p class="black-text">Obtiens de précieuses remarques constructives, pertinentes et efficaces pour améliorer ton document pdf grâce aux Large Language Model (LLM) comme chatGPT.</p>
        <p>Es-tu prêt à connaître l'avis d'Hina sur ton pitch deck ?</p>
        <a href="#commencer"><button>Commencer</button></a>
      </div>
      <div class="card-container">
        <div class="card">
          <img src="../assets/A-oil-painting-of-an-efficient-pitch-deck.png" alt="Card Image 1">
          <div class="card-content">
            <h2>Efficacité</h2>
            <p>Un retour clair, sur chaque page de ton document, pour te permettre de l'améliorer.</p>
          </div>
        </div>
        <div class="card">
          <img src="../assets/A-oil-painting-of-a-gear-wheel.png" alt="Card Image 2">
          <div class="card-content">
            <h2>Rapidité</h2>
            <p>Quelques secondes suffisent.</p>
            <p>Tu as à peine le temps de préparer ton café :)</p>
          </div>
        </div>
        <div class="card">
          <img src="../assets/A-oil-painting-of-a-padlock-in-a-crest.png" alt="Card Image 3">
          <div class="card-content">
            <h2>Confidentialité</h2>
            <p>Aucune donnée n'est vendue ou transmise à un tiers.</p>
            <p>Ton document et toutes les informations sont détruites dans les 24 heures avec l'offre private sinon elles restent en interne (30 jours) afin de nous permettre d'améliorer notre produit.</p>
          </div>
        </div>
      </div>
      <div class="upload-container" id="commencer">
        <div class="upload-box" @drop="handleDrop">
            <p>Glissez et déposez un fichier PDF ici ou</p>
            <input type="file" accept=".pdf" @change="handleFileUpload">
            <button @click="importfile">Importer présentation</button>
        </div>

        <div>
          <label>
            <input type="checkbox" v-model="conditionsUtilisation" />
            J'accepte les <router-link to="/cgu" target="_blank">conditions générales d'utilisation</router-link>
          </label>
        </div>

        <div>
          <label>
            <input type="checkbox" v-model="charteRGPD" />
            J'accepte la <router-link to="/politique-de-confidentialite" target="_blank">politique de confidentialité</router-link>
          </label>
        </div>

        <div>
            <label>
              <input type="checkbox" v-model="confidentiality" />
                Je souhaite rendre certains mots confidentiels.
            </label>
        </div>

        <div>
          <input type="checkbox" v-model="demandeCodePromotion" />
          <label>Je dispose d'un code promotionnel</label>
        </div>
        <div v-if="demandeCodePromotion">
          <input type="text" v-model="codePromotion" placeholder="Entrez le code promotionnel" />
          <button @click="validerCodePromotion">Valider le code</button>
        </div>
        <div v-else>
          <div>
            <input type="checkbox" v-model="beneficierReduction" />
            <label>Bénéficier d'une réduction</label>
          </div>
          <div v-if="beneficierReduction">
            <div class="content-box">
              En bénéficiant d'une réduction je consens accepter à céder mon contenu à des fins d'utilisation interne pour améliorer le produit et le besoin (et que mes données soient conservées durant 30 jours).
            </div>
            <stripe-buy-button
              buy-button-id="buy_btn_1OFNE2J8A86Jqk1Yx4JrTSfH"
              publishable-key="pk_live_51NpHEhJ8A86Jqk1YXH4sK2stgc0YQcFJbhamOkdh9RM2NwWiCTL288J2Qc1kNmT6DpdIZ9Uy7iF2sEeiMyK0Tg4S00k9LrcY9v"
            >
            </stripe-buy-button>
          </div>
          <div v-else>
            <div class="content-box">
              En souscrivant à l'offre private je m'assure que mes données confidentielles soient supprimées dans les 24 heures et qu'elles ne soient ni lu, ni vu, ni utiliser à des fins d'amélioration du projet et d'identification du besoin.
            </div>
            <stripe-buy-button
              buy-button-id="buy_btn_1OFNHZJ8A86Jqk1YfiRJQyVv"
              publishable-key="pk_live_51NpHEhJ8A86Jqk1YXH4sK2stgc0YQcFJbhamOkdh9RM2NwWiCTL288J2Qc1kNmT6DpdIZ9Uy7iF2sEeiMyK0Tg4S00k9LrcY9v"
            >
            </stripe-buy-button>
          </div>
        </div>

      </div>

        <div v-for="notification in notifications" :key="notification.id" class="notification">
          {{ notification.message }}
        </div>

        <div v-if="apiResponse && apiResponse.length > 0">
          <p class="box">
            Ajouter une référence* pour toutes les valeurs que vous souhaitez protéger avant l'envoi au modèle LLM. <br><br>
            <small>
              Par référence il est entendu un autre mot qui ferait référence au mot d'origine mais pour le masquer avant l'envoi des données au modèle LLM (chatGPT/openAI à ce jour).<br>
              Vous pouvez laisser blanc si vous n'avez pas besoin de changer le mot et aller directement cliquer sur <a href="#start">Enregistrer & Envoyer</a> pour obtenir toutes vos remarques sur la présentation.
            </small>
          </p>
          <form @submit.prevent="saveChanges">
            <div v-for="(value, index) in apiResponse" :key="index">
              <input type="text" name="datatohide" :value="value" readonly />
              <input type="text" name="dataref" v-model="editedResponse[index]" />
            </div>

            <p><small>Cliquez sur enregistrer et envoyer pour enregistrer vos références et envoyer votre présentation au modèle LLM pour obtenir les remarques de votre présentation.</small></p>
            <button type="submit" id="start" @click="SendRequestWithDataProtected">Enregistrer & Envoyer</button><br>
            <!-- <button type="submit" id="start" @click="this.chatbot">Envoyer sans confidentialité</button> -->
          </form>
        </div>
        
        <div v-for="notification in notifications" :key="notification.id" class="notification">
          {{ notification.message }}
        </div>

        <div v-if="contents && contents.length > 0">
          <div v-for="content in contents" :key="content.id" class="answerBox">
            <div v-if="content.message" style="white-space: pre-line;">
              {{ content.message }}
            </div> 

            <div v-else>
              "En cours de traitement. Merci de patienter."
            </div>
          </div>
        </div>

        <div id="parentElement">
          <div v-if="showEndChat">
            Est-ce que l'expérience vous a plu ?
            <button @click="sendRequestExperience(true)">Oui</button>
            <button @click="sendRequestExperience(false)">Non</button>
          </div>

          <div v-if="showEmailForm">
            Voulez-vous recevoir l'échange par email ?
            <input type="text" name="firstname" placeholder="Prénom" v-model="firstName">
            <input type="email" name="email" placeholder="Email" v-model="email">
            <button @click="submitEmailForm">Soumettre</button>
          </div>

          <div v-if="showFinalMessage" class="finalMessage">
            <b>Fin de la conversation. Merci de votre confiance. Nous vous souhaitons une excellente journée.</b>
          </div>
        </div>
      </div>
  </template>

  <script>
  import axios from 'axios';
  import { random } from 'lodash';
  
  
  //import { useNotification } from './useNotification'; // NE FONCTION PAS 

  import { ref } from 'vue';


  // Définir l'en-tête X-CSRFToken pour toutes les requêtes sortantes
  axios.defaults.xsrfHeaderName = 'X-CSRFToken'; // Définissez le nom de l'en-tête CSRF attendu par Django
  axios.defaults.xsrfCookieName = 'csrfToken'; // Définissez le nom du cookie CSRF utilisé par Django

  // Définir l'URL de base en fonction de l'environnement
  let baseURL = '';

  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:8000';
  } else {
    baseURL = 'https://hinacortus.com';
  }

  import Cookies from 'js-cookie';
  const csrfToken = Cookies.get('csrf_token');
  /*
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrfToken
  }; */  

  export default {
    setup() {
      const notifications = ref([]);
      const contents = ref([]);

      const showEndChat = ref(false);
      const showEmailForm = ref(false);
      const showFinalMessage = ref(false);

      const firstName = ref([]);
      const email = ref([]);

      function addNotification(message) {
        const newNotification = {
          id: Date.now(),
          message: message
        };

        notifications.value.push(newNotification);

        setTimeout(() => {
          notifications.value = notifications.value.filter(
            notification => notification.id !== newNotification.id
          );
        }, 7000);
      }

      function showNotification(message) {
        addNotification(message);
      }

      function addContent(message) {
        const newContent = {
          id: Date.now(),
          message: message
        };

        contents.value.push(newContent);
      }

      function showContent(message) {
        addContent(message);
      }

      function importfile() {
        // Vérifier si un fichier a été sélectionné
        if (!this.selectedFile) {
          showNotification("Aucun fichier sélectionné. Merci de choisir un fichier.");
          return;
        }

        if (this.confidentiality) {
          //console.log('Confidentialité is: ', this.confidentiality);
          showNotification('Confidentialité est: ', this.confidentiality);
        }

        // Vérifier si les cases à cocher sont cochées
        if (this.conditionsUtilisation && this.charteRGPD) {
          // Les conditions d'utilisation et la charte RGPD ont été acceptées
          // Effectuez ici votre logique d'import de présentation

          // Créer un objet FormData pour l'envoi du fichier
          const formData = new FormData();
          formData.append('file', this.selectedFile);
          formData.append('user_id', this.UserId);
          formData.append('csrfmiddlewaretoken', csrfToken);

          try {
            // Envoyer le fichier à l'API en utilisant axios (ou tout autre bibliothèque de requêtes HTTP)
            axios.post(`${baseURL}/upgrademydeck/uploadFile`, formData)
              .then(response => {
                // Le fichier a été téléchargé avec succès sur le serveur
                //console.log('uploadFile:', response.status); 
                //console.log('data: ', response.data);
                //console.log('message: ', response.data.message);
                showNotification(response.data.message);
                if (response.status == 200){
                  //console.log('Fichier téléchargé avec succès');
                  this.readFile();
                }
              })
              .catch(error => {
                // Une erreur s'est produite lors du téléchargement du fichier
                showNotification('Une erreur est survenue lors du téléchargement du fichier.', error);
              });

            //this.readFile();
          } catch (error) {
            // Gérer les erreurs d'importation
            showNotification("Une erreur est apparue lors de l'importation de la présentation", error);
          }
        } else {
          // Afficher un message d'erreur si les cases ne sont pas cochées
          showNotification("Veuillez accepter les conditions d'utilisation et la charte RGPD pour continuer.");
        }
      }

      function readFile() {
        // Effectuez ici votre logique de lecture de fichier
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('user_id', this.UserId);
        formData.append('csrfmiddlewaretoken', csrfToken);
        // Utilisez l'URL appropriée pour accéder au fichier sur le serveur
        axios.post(`${baseURL}/upgrademydeck/readPDF`, formData)
          .then(response => {
            // Le fichier a été téléchargé avec succès sur le serveur
            showNotification('Fichier opened successfuly.');
            //console.log('readPDF: ', response.data);
            //console.log('numberpages: ', response.data.numberpages);
            if (response.data.numberpages !== undefined) {
              showNotification(response.data.numberpages.toString() + ' pages found.');
            } else {
              showNotification('Number of pages not available. Please try again in fews secondes.');
            }
            showNotification('Merci de patienter quelques instants.');

            this.pages = response.data.pages;

            // const pages = response.data.pages; peut utiliser ainsi mais variable pas en mémoire dans d'autres fonctions - aucun cas utiliser this.pages
              //console.log(pages);

              // Appelez la fonction suivant chat
              if (response.status == 200){
                // SI L UTILISATEUR N A PAS BESOIN DE PROTEGER DES DONNEES
                if (this.confidentiality == false) {

                  for (const page in this.pages) {
                    //showContent(pages[page]); // VOIR SI JE VEUX AFFICHER LE CONTENU ENVOYER mais le mettre sur la gauche et avoir la réponse sur la droite ! a voir
                    //console.log(page);
                    this.chatbot(this.pages[page]);
                  }
                }
                
                // si l utilisateur veut protéger certaines données
                else {
                  this.textconfidentiality(response.data.allcontent, response.data.pages);
                }
                //this.SendRequestWithDataProtected(pages[page]);   
             
              }
          })
          .catch(error => {
            // Une erreur s'est produite lors du téléchargement du fichier
            showNotification('Erreur lors du téléchargement du fichier. Merci de recommencer et si le problème persiste, merci de nous le signaler.', error);
          });
      }

      const endChat = () => {
        showEndChat.value = true;
        const questionContainer = document.createElement('div');
        questionContainer.textContent = 'Est-ce que l\'expérience vous a plu ?';

        const yesButton = document.createElement('button');
        yesButton.textContent = 'Oui';
        yesButton.addEventListener('click', () => {
          this.sendRequestExperience(true);
          this.activity('Retour expérience', 'true');
          this.askForEmail();
        });

        const noButton = document.createElement('button');
        noButton.textContent = 'Non';
        noButton.addEventListener('click', () => {
          this.sendRequestExperience(false);
          this.activity('Retour expérience', 'false');
          this.showFinalMessage();
        });

        questionContainer.appendChild(yesButton);
        questionContainer.appendChild(noButton);

        // Ajoutez le conteneur de la question à votre DOM pour l'afficher à l'utilisateur
        // (par exemple, en l'insérant dans un élément parent approprié)
      }

      const sendRequestExperience = (isPositive) => {
        // Utilisez la valeur de 'satisfied' pour envoyer la réponse à l'API /reqExperience
        // Effectuez ici la requête HTTP appropriée pour envoyer les données à l'API
        const formData = new FormData();
        //formData.append('user_id', this.UserId);
        //console.log('user_id: ', this.UserId);
        formData.append('csrfmiddlewaretoken', csrfToken);
        formData.append('FeedBack', 'isPositive');
        // Utilisez l'URL appropriée pour accéder au fichier sur le serveur
        axios.post(`${baseURL}/upgrademydeck/getfeeddback`, formData)
          .then(response => {
            // Le fichier a été téléchargé avec succès sur le serveur
            //console.log('response feedback: ', response);
            if (response.data == 'success'){
              showNotification("Retour d'expérience pris en compte. Merci de votre partage.");
              //console.log('Retour expérience fonction: ', response.data);
            }
            else {
              showNotification("Une erreur nous est survenue. Nous la faisons remontée et nous vous remercions pour votre collaboration à améliorer notre produit.");
            }
            
          });
        // Code pour envoyer la demande d'expérience
        if (isPositive) {
          askForEmail();
        } else {
          showFinalMessage.value = true;
        }
      };

      const askForEmail = () => {
        showEmailForm.value = true;
        const emailContainer = document.createElement('div');
        emailContainer.textContent = 'Si vous souhaitez recevoir l\'échange par email: ?';

        const firstNameInput = document.createElement('input');
        firstNameInput.setAttribute('type', 'text');
        firstNameInput.setAttribute('placeholder', 'Prénom');

        const emailInput = document.createElement('input');
        emailInput.setAttribute('type', 'email');
        emailInput.setAttribute('placeholder', 'Email');

        const submitButton = document.createElement('button');
        submitButton.textContent = 'Soumettre';
        submitButton.addEventListener('click', () => {
          const firstName = firstNameInput.value;
          const email = emailInput.value;

          if (firstName && email && this.validateEmail(email)) {
            // Effectuez ici la requête HTTP appropriée pour envoyer les données à l'API
          } else {
            // Gérez le cas où les champs ne sont pas remplis correctement
            showNotification("Merci d'inclure votre prénom et e-mail.");
          }
        });

        emailContainer.appendChild(firstNameInput);
        emailContainer.appendChild(emailInput);
        emailContainer.appendChild(submitButton);
      };

      const displayFinalMessage = () => {
        const finalMessage = document.createElement('div');
        finalMessage.textContent = '';
      };

      const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
      };

      function textconfidentiality(req, pages) {
        // Effectuez ici votre logique de lecture de fichier
        showNotification('Recherche des noms ou autres informations confidentielles à protéger.');
        const formData = new FormData();
        formData.append('user_id', this.UserId);
        // console.log(req); 
        formData.append('text', req);
        formData.append('pages', pages);
        formData.append('csrfmiddlewaretoken', csrfToken);
        // Utilisez l'URL appropriée pour accéder au fichier sur le serveur
        axios.post(`${baseURL}/upgrademydeck/textconfidentiality`, formData)
          .then(response => {
            showNotification('Reconnaissance des noms, des emails et téléphones');
            //console.log('textconfidentiality: ', response.data);
            this.apiResponse = response.data.names;
            //console.log(this.apiResponse);
            //this.showContent(response.data.names);
            //this.SendRequestWithDataProtected(response.data.allcontent, response.data.pages);
            //this.SendRequestWithDataProtected(pages[page]);
            
          })
          .catch(error => {
            showNotification("Erreur lors de la confidentialité des données", error);
          });
      }

      function SendRequestWithDataProtected(req) {
        // Effectuez ici votre logique de lecture de fichier
        //console.log('Add Data Protected in progress & Send request');
        showNotification("Préparation à la protection des données");
          // Créez un tableau pour stocker les éléments modifiés
          const modifiedElements = [];

          for (let index in this.editedResponse) {
            if (Object.prototype.hasOwnProperty.call(this.editedResponse, index)) {
              const newValue = this.editedResponse[index];
              const originalValue = this.apiResponse[index];

              if (newValue !== originalValue) {
                modifiedElements.push({ index, value: newValue });
              }
            }
          }

          //console.log('modifiedElements: ', modifiedElements);

        const formData = new FormData();
        formData.append('user_id', this.UserId);
        // Convertir les données en JSON
        const jsonData = JSON.stringify(req);
        // VOIR SI NENVOIE PAS formData.append('pages', this.pages);

        //console.log('jsonData: ', jsonData); 
        formData.append('req', jsonData);
        formData.append('csrfmiddlewaretoken', csrfToken);
        // Utilisez l'URL appropriée pour accéder au fichier sur le serveur
        axios.post(`${baseURL}/upgrademydeck/adddataprotected`, formData, {headers : {'Content-Type': 'application/json'}})
          .then(response => {
            //console.log('Requête pour ajouter les donnees a proteger');
            showNotification("Requête pour ajouter les donnees à proteger");
            //console.log('Add data protecte response: ', response.data);
            this.showContent(response.data.answer);

            // Une fois toutes les données à protéger enregistrées, envoyer la requête à chatGPT
            //const pages = response.data.pages;
            //console.log("pages :", this.pages);

            // Appelez la fonction suivant chat
            //console.log("response.status :", response.status);
            if (response.status == 201){
              for (const page in this.pages) {
                //showContent(pages[page]); // VOIR SI JE VEUX AFFICHER LE CONTENU ENVOYER mais le mettre sur la gauche et avoir la réponse sur la droite ! a voir
                //console.log('page à envoyer à chatgpt: ', this.pages[page]);
                //this.chat(pages[page]);
                this.chatbot(this.pages[page]);
              }
            }
          })
          .catch(error => {
            showNotification("Erreur lors de l'envoi au chatbot", error);
          });
      }

      function chatbot(req) {
        // Effectuez ici votre logique de lecture de fichier
        const formData = new FormData();
        formData.append('user_id', this.UserId);
        // console.log(req); 
        formData.append('req', req);
        formData.append('csrfmiddlewaretoken', csrfToken);
        //console.log('formData: ', formData);
        
        this.chatAnswer = []; // Initialiser la liste vide

        // Utilisez l'URL appropriée pour accéder au fichier sur le serveur
        axios.post(`${baseURL}/upgrademydeck/chatbot`, formData)
          .then(response => {
            //console.log('Requête envoyé à chatGPT');
            //console.log('chatbot: ', response.data);

            this.chatAnswer.push('<div class="answer">' + response.data.answer  + '</div><br><br>');

            this.showContent(response.data.answer);
            this.answers.push(response.data.answer);
          })
          .catch(error => {
            showNotification("Erreur lors de l'envoi à l'agent conversationnel", error);
          });
          endChat();
      }

      function activity(post, meta) {
        const formData = new FormData();
        formData.append('user_id', this.UserId);
        formData.append('post', post);
        formData.append('meta', meta);
        formData.append('csrfmiddlewaretoken', csrfToken);
        axios.post(`${baseURL}/upgrademydeck/activity`, formData)
          .then(response => { console.log('activity: ', response.data);})
          .catch(error => { console.log("Erreur: ", error);});
      }

      return {
        notifications,
        showNotification,
        contents,
        showContent,
        importfile,
        readFile,
        textconfidentiality,
        SendRequestWithDataProtected,
        chatbot,
        endChat,
        sendRequestExperience,
        askForEmail,
        //submitEmailForm,
        showEndChat,
        showEmailForm,
        showFinalMessage,
        displayFinalMessage,
        validateEmail,
        firstName,
        email,
        activity
      };
    },
    mounted(){
      this.UserId = this.genererNumeroAleatoire();

      const stripeScript = document.createElement('script');
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/buy-button.js');
      document.head.appendChild(stripeScript);
    },
    data() {
        return {
            isDragOver: false,
            selectedFile: null,
            conditionsUtilisation: false,
            charteRGPD: false,
            file: null,
            UserId: null,
            pages: null,
            apiResponse: [], // Réponse de l'API
            editedResponse: [], // Réponse modifiée
            confidentiality: false,
            answers: [],
            demandeCodePromotion: false,
            beneficierReduction: false,
            codePromotion: '',
            chatAnswer: ''
        };
    },
    methods: {
      validerCodePromotion() {
        if (this.codePromotion.toLowerCase() === 'code') {
        this.showNotification('Votre code promotionnel a bien été pris en compte.');
        this.activity('code promo', 'valid');
          // Le code promotionnel est valide, vous pouvez afficher le lien Stripe 1
          this.afficherLienStripe1 = true;
        } else {
          // Le code promotionnel est invalide, afficher le lien Stripe 2
          this.afficherLienStripe1 = false;
        }
      },
      genererNumeroAleatoire () {    // Générez un numéro aléatoire entre 0 et 99999999
          const numeroAleatoire = random(0, 99999999);
          console.log('user_id: ', numeroAleatoire);
          return numeroAleatoire
      },
      handleFileUpload(event) {
          this.selectedFile = event.target.files[0];
          this.importfile();
      },
      handleDragEnter(event) {
        event.preventDefault();
        this.isDragOver = true;
      },
      handleDragOver(event) {
        event.preventDefault();
      },
      handleDragLeave(event) {
        event.preventDefault();
        this.isDragOver = false;
      },
      handleDrop(event) {
        event.preventDefault();
        this.isDragOver = false;
        this.selectedFile = event.dataTransfer.files[0];
        // Faites quelque chose avec le fichier, par exemple, appelez une méthode d'importation
        this.importfile();
      },
      created() {
        this.genererNumeroAleatoire(); // Appeler la fonction lors de la création du composant
      },
      saveChanges() {
        // Logique pour enregistrer les modifications
        console.log('saveChanges');
        //console.log(this.editedResponse);
        // Filtrer les réponses avec dataref non nul
        const filteredResponses = this.apiResponse.reduce((acc, value, index) => {
          if (this.editedResponse[index] !== '' && this.editedResponse[index] !== null && this.editedResponse[index] !== undefined) {
            acc.push({
              datatohide: value,
              dataref: this.editedResponse[index]
            });
          }
          return acc;
        }, []);

        // Afficher les réponses filtrées dans la console
        console.log(filteredResponses);
        console.log('Envoyer les informations à protéger');
        this.showNotification('Envoie du contenu à protéger');
        this.SendRequestWithDataProtected(filteredResponses);
      },

      submitEmailForm() {
        // Code pour soumettre le formulaire d'email
        // Votre logique de soumission du formulaire par email
        // Cette méthode sera appelée lorsque vous cliquez sur le bouton "Soumettre"
        // Vous pouvez accéder aux valeurs des champs "firstName" et "email" via this.firstName et this.email
        const formData = new FormData();
        console.log('Demande de compte rendu par email', this.firstName + ' ' + this.email);
        this.activity('Demande de compte rendu par email', this.firstName + ' ' + this.email);
        // formData.append('user_id', this.UserId);
        //console.log('this.UserId: ', this.UserId);
        formData.append('firstname', this.firstName);
        formData.append('email', this.email);
        console.log('answers', this.chatAnswer);
        formData.append('answers', this.chatAnswer);
        formData.append('csrfmiddlewaretoken', csrfToken);
        // Utilisez l'URL appropriée pour accéder au fichier sur le serveur
        axios.post(`${baseURL}/upgrademydeck/sendemail`, formData)
          .then(response => {
            console.log('response sendemail', response);
            // Le fichier a été téléchargé avec succès sur le serveur
            this.showNotification("Email envoyé.");
            console.log('Reponse sendEmail: ', response.data);
            
          });

        this.showFinalMessage = true;
      }
    }
  }
  </script>
  
  <style>
  .landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/pencil.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
  
  .content {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 30px;
    margin: 2rem;
    padding: 1rem;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff5722;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .card {
    flex-basis: calc(33.33% - 20px);
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px;
    margin-bottom: 30px;
  }

  @media (max-width: 600px) {
      .card-container {
          justify-content: center;
      }
  }

  .card-container-clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 300px;
  margin: 10px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
  
  .card img {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    background-color: #f5f5f5;
    padding: 10px;
    text-align: justify;
  }
  
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .upload-container {
    display: grid;
    justify-content: center;
    align-items: left;
    border: 2px dashed #ccc;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 2rem;
  }
  
  .upload-box {
    text-align: center;
  }
  
  .upload-box p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .upload-box button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff5722;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .client-feedbacks {
  position: relative;
  z-index: 1;
}

.video-container {
  position: relative;
  z-index: 0;
}

/* Styles supplémentaires pour la mise en page */
.client-feedbacks {
  padding: 20px;
  background-color: #f0f0f0;
}

.video-container {
  margin-bottom: 20px;
}

.notification {
  background-color: #ff5722;
  color: black;
  padding: 10px;
  margin-top: 5px;
}

.answerBox{
  background-color: #f0f0f0;
  color: black;
  margin: 2rem;
  padding: 1rem;
}

@media screen and (min-width: 800px) {
  .answerBox{
    width: 500px;
  }
}

.finalMessage{
  margin: 1rem;
  font-size: 1.2rem;
  color: #ff5722;
}

input{
  margin: 1rem;
  padding: 0.8rem;
  border-radius: 0.2rem;
}

.box{
  width: 500px;
  background-color: white;
  padding: 1rem;
  margin: 2rem;
  text-align: center;
}
/*
.yellow-text{
  color:#F7D458;
}
*/
.orange-text{
  color: #ff5722;
}
.black-text{
  color: black;
  width: 500px;
  margin: auto;
  text-align: center;
}

@media (max-width: 600px) {
  .black-text{
    width: auto;
    }
  }

.content-box{
  width:400px; text-align: justify; padding: 2rem; margin:1rem;
}
</style>
