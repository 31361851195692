<template>
    <div class="container">
      <h1>Mentions Légales</h1>
      
      <h2>Informations sur l'entreprise</h2>
      <p>
        Hina & Cortus OÜ, <br>
        enregistré sous le numéro : 16556127 <br>
        registré à l'adresse : Harju maakond, Tallinn, Kesklinna linnaosa, Tartu mnt 67/1-13b, 10115 <br>
        e-mail : contact@hinacortus.com 
      </p>
      
      <h2>Responsable de la publication</h2>
      <p>
        Responsable de la publication: Julien Godard
        e-mail du responsable de la publication: contact@hinacortus.com
      </p>
      
      <h2>Hébergement</h2>
      <p>
        Notre site est hébergé sur les serveurs de Gandi SAS à l'adresse: 63-65 boulevard Masséna 75013 Paris, France
      </p>
      
      <h2>Propriété intellectuelle</h2>
      <p>
        Tous les droits de propriété intellectuelle relatifs au contenu de notre site (y compris, mais sans s'y limiter, les textes, les graphiques, les images et les logos) sont réservés. Toute reproduction, distribution ou utilisation non autorisée du contenu est strictement interdite.
      </p>
      
      <h2>Responsabilité</h2>
      <p>
        Nous nous efforçons de fournir des informations précises et à jour sur notre site, mais nous ne pouvons garantir l'exactitude et l'exhaustivité de ces informations. Nous déclinons toute responsabilité quant à l'utilisation ou à l'interprétation des informations fournies sur notre site.
      </p>
      
      <h2>Liens vers des sites tiers</h2>
      <p>
        Notre site peut contenir des liens vers des sites tiers. Nous n'exerçons aucun contrôle sur ces sites et déclinons toute responsabilité quant à leur contenu ou leurs pratiques de confidentialité. Nous vous encourageons à consulter les politiques de confidentialité et les conditions d'utilisation de ces sites tiers.
      </p>
      
      <h2>Conditions d'utilisation</h2>
      <p>
        Décrivez les conditions d'utilisation de votre site ou de votre application, y compris les restrictions d'accès, les règles de conduite des utilisateurs, les droits de propriété intellectuelle, etc.
      </p>
      
      <h2>Clause de non-responsabilité</h2>
      <p>
        Indiquez que vous déclinez toute responsabilité concernant l'utilisation du site ou de l'application et les éventuels préjudices résultant de cette utilisation, y compris les virus informatiques, les pertes de données, etc.
      </p>
      
      <h2>Droit applicable</h2>
      <p>
        Précisez la loi applicable qui régit votre site ou votre application, ainsi que la juridiction compétente en cas de litige.
      </p>

      <h2>Clause de réserve de droits</h2>
      <p>
        Affirmez votre réserve de droits concernant les éléments non explicitement mentionnés dans les mentions légales, et précisez que toute utilisation non autorisée de votre contenu peut constituer une violation de la législation en vigueur.
      </p>

    </div>
  </template>
  
  <script>
  export default {
    name: 'MentionsLegales',
    // Le reste de votre logique JavaScript
  }
  </script>
  
  <style scoped>
  .container{
    margin: 3rem;
    padding: 2rem;
    text-align: justify;
  }
  </style>