import { createRouter, createWebHistory } from 'vue-router';

import UpgradeMyDeck from '@/components/UpgradeMydeck';
import CGU from '@/components/CGU';
import MentionsLegales from '@/components/MentionsLegales';
import PolitiqueConfidentialite from '@/components/PolitiqueConfidentialite';

const routes = [
    {
      path: '/',
      name: 'UpgradeMyDeck',
      component: UpgradeMyDeck
    },
    {
      path: '/cgu',
      name: 'CGU',
      component: CGU
    },
    {
      path: '/mentions-legales',
      name: 'MentionsLegales',
      component: MentionsLegales
    },
    {
      path: '/politique-de-confidentialite',
      name: 'PolitiqueConfidentialite',
      component: PolitiqueConfidentialite
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;