<template>
    <div class="container">
      <h1>Conditions Générales d'Utilisation</h1>
      <p>Date d'entrée en vigueur : [Date d'entrée en vigueur]</p>
      
      <h2>1. Accès et utilisation du Service</h2>
      <p>1.1 Inscription : L'Utilisateur doit s'inscrire et créer un compte pour accéder au Service. Les informations fournies lors de l'inscription doivent être exactes, complètes et à jour.</p>
      <p>1.2 Utilisation autorisée : L'Utilisateur est autorisé à utiliser le Service conformément aux présentes CGU et à toutes les lois et réglementations applicables. L'Utilisateur ne doit pas utiliser le Service à des fins illicites ou frauduleuses.</p>
      <p>1.3 Accès sécurisé : L'Utilisateur est responsable de la confidentialité de ses identifiants de connexion et doit prendre les mesures nécessaires pour protéger son compte contre tout accès non autorisé.</p>
      
      <h2>2. Propriété intellectuelle</h2>
      <p>2.1 Droits de propriété : L'Utilisateur reconnaît que tous les droits de propriété intellectuelle relatifs au Service et à son contenu (y compris, mais sans s'y limiter, les logiciels, les textes, les graphiques, les images, les vidéos et les marques) appartiennent à [Nom de votre entreprise] ou à ses concédants de licence.</p>
      <p>2.2 Licence limitée : [Nom de votre entreprise] accorde à l'Utilisateur une licence limitée, non exclusive, non transférable et révocable d'utiliser le Service aux fins prévues par ces CGU. L'Utilisateur ne peut pas reproduire, modifier, distribuer ou créer des œuvres dérivées à partir du Service sans autorisation écrite préalable.</p>
      
      <h2>3. Confidentialité</h2>
      <p>3.1 Collecte de données : [Nom de votre entreprise] collecte et traite les données personnelles de l'Utilisateur conformément à sa Politique de Confidentialité. En utilisant le Service, l'Utilisateur consent à la collecte et au traitement de ses données personnelles par [Nom de votre entreprise].</p>
      <p>3.2 Sécurité des données : [Nom de votre entreprise] met en place des mesures de sécurité appropriées pour protéger les données personnelles de l'Utilisateur. Cependant, l'Utilisateur reconnaît que la sécurité des transmissions de données via Internet ne peut être garantie à 100%.</p>
      
      Limitation de responsabilité
        4.1 Exclusion de garanties : Le Service est fourni "tel quel" et [Nom de votre entreprise] ne fait aucune déclaration ni garantie quant à sa disponibilité, sa fiabilité, son exactitude ou son adéquation à un usage particulier.
        4.2 Limitation de responsabilité : [Nom de votre entreprise] ne sera pas responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs découlant de l'utilisation ou de l'impossibilité d'utiliser le Service, même si [Nom de votre entreprise] a été informé de la possibilité de tels dommages.

        Modification des CGU
        [Nom de votre entreprise] se réserve le droit de modifier les présentes CGU à tout moment. Toute modification sera publiée sur le site du Service et entrera en vigueur à la date indiquée. Il est de la responsabilité de l'Utilisateur de consulter régulièrement les CGU pour prendre connaissance des éventuelles modifications.

        Résiliation
        Les parties peuvent résilier ces CGU à tout momenten respectant un préavis raisonnable. En cas de résiliation, l'Utilisateur doit cesser toute utilisation du Service et supprimer toutes les données et informations associées à son compte.

        Dispositions générales
        7.1 Intégralité de l'accord : Les présentes CGU constituent l'intégralité de l'accord entre [Nom de votre entreprise] et l'Utilisateur en ce qui concerne l'utilisation du Service, et elles remplacent tous les accords antérieurs ou concurrents, écrits ou verbaux.
        7.2 Loi applicable et juridiction compétente : Les présentes CGU sont régies et interprétées conformément aux lois de [Pays] et tout litige découlant des présentes sera soumis à la juridiction exclusive des tribunaux de [Ville].
        7.3 Divisibilité : Si une disposition des présentes CGU est jugée invalide ou inapplicable, les autres dispositions resteront en vigueur.
        7.4 Notifications : Toutes les notifications ou communications relatives aux présentes CGU doivent être faites par écrit et envoyées à l'adresse indiquée sur le site du Service.
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'CGU',
    // Le reste de votre logique JavaScript
  }
  </script>
  
  <style scoped>
  .container{
    margin: 3rem;
    padding: 2rem;
    text-align: justify;
  }
  </style>