<template>
  <router-link to="/"><img alt="Upgrade My Desk logo" src="./assets/UpgradeMyPitchDeck.png" height="100"></router-link>
  
  <!-- <UpgradeMydesk /> LA VUE RESTE TOUJOURS AFFICHE -->
  <router-view></router-view>

  <footer>
    <div class="footer-content">
      <div class="footer-section">
        <h3>Un besoin, Une solution</h3>
        <ul>
          <li>Un service disponible à toutes et tous. Nous espérons que notre SaaS est la réponse que vous attendiez !</li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Informations légales</h3>
        <ul>
          <li><router-link to="/cgu">Conditions générales d'utilisation</router-link></li>
          <li><router-link to="/mentions-legales">Mentions légales</router-link></li>
          <li><router-link to="/politique-de-confidentialite">Politique de confidentialité</router-link></li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Autres produits</h3>
        <ul>
          <li><a href="#">Pitch (bientôt disponible)</a></li>
          <li><a href="#">Challenge (bientôt disponible)</a></li>
          <li><a href="#">Create My Desk (en projet)</a></li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; 2023 Hina & Cortus. Tous droits réservés.</p>
    </div>
  </footer>

</template>

<script>
//import UpgradeMydesk from './components/UpgradeMydesk.vue'

export default {
  name: 'App',
  //components: {
  // UpgradeMydesk,
  //}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
}

footer {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  flex: 1 1 300px;
  margin-bottom: 20px;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  text-decoration: none;
  color: #333;
}

.footer-bottom {
  margin-top: 20px;
}
</style>
