<template>
    <div class="container">
      <h1>Politique de Confidentialité</h1>
      
      <p>
        Votre confidentialité est importante pour nous. Cette Politique de Confidentialité explique quelles informations nous collectons, comment nous les utilisons et comment nous les protégeons. En utilisant notre Service, vous acceptez la collecte et l'utilisation de vos informations conformément à cette Politique de Confidentialité.
      </p>
      
      <h2>Collecte et utilisation des informations</h2>
      <p>
        Nous collectons les informations que vous nous fournissez directement, telles que votre nom, votre adresse e-mail et toute autre information que vous choisissez de nous communiquer. Ces informations restent confidentielles et ne seront pas partagées avec des tiers sans votre consentement.
      </p>
      <p>
        Nous utilisons les informations collectées pour vous fournir le Service, pour communiquer avec vous, pour améliorer notre Service et pour assurer la confidentialité de vos données. Toutes les informations personnelles que vous nous fournissez sont stockées de manière sécurisée et ne seront pas conservées plus longtemps que nécessaire.
      </p>
      
      <h2>Stockage des données</h2>
      <p>
        Les données que vous fournissez sont stockées sur les serveurs de notre prestataire de services, G. Nous avons pris des mesures pour nous assurer que vos données sont sécurisées et protégées conformément aux meilleures pratiques du secteur. Nous nous engageons à maintenir la confidentialité de vos informations.
      </p>
      
      <h2>Suppression des données</h2>
      <p>
        Nous nous engageons à supprimer toutes les données transmises dans un délai de 24 heures à compter de leur réception, sauf indication contraire de votre part ou si nous sommes légalement tenus de les conserver.
      </p>
      
      <h2>Confidentialité</h2>
      <p>
        Nous apportons une attention particulière à la confidentialité de vos informations. Nous nous engageons à prendre toutes les mesures raisonnables pour protéger vos données contre tout accès non autorisé, toute divulgation ou toute utilisation abusive.
      </p>
      
      Utilisation des cookies et des technologies de suivi : Expliquez comment vous utilisez les cookies et les technologies de suivi sur votre site ou votre application, y compris les cookies tiers utilisés à des fins de suivi ou de publicité ciblée. Mentionnez également comment les utilisateurs peuvent gérer leurs préférences en matière de cookies.

      <h2>Partage d'informations avec des tiers</h2>
      <p>
         Indiquez si vous partagez des informations personnelles avec des tiers et dans quelles circonstances. Précisez si vous utilisez des services ou des sous-traitants tiers pour le traitement des données et fournissez des détails sur les mesures de sécurité mises en place.
      </p>

      <h2> Protection des données</h2> 
      <p>
        Décrivez les mesures de sécurité que vous avez mises en place pour protéger les informations personnelles de vos utilisateurs. Cela peut inclure des protocoles de cryptage, des contrôles d'accès, des audits de sécurité réguliers, etc.
      </p>

      <h2>Droits des utilisateurs</h2>
      <p>
        Informez les utilisateurs de leurs droits en matière de protection des données, tels que le droit d'accéder à leurs informations personnelles, de les rectifier, de les supprimer ou de s'opposer à leur traitement. Expliquez comment les utilisateurs peuvent exercer ces droits et comment vous traiterez leurs demandes.
      </p>

      <h2>Liens vers des sites tiers</h2>
      <p>
        Si votre site ou votre application contient des liens vers des sites tiers, indiquez que votre Politique de confidentialité ne s'applique pas à ces sites et encouragez les utilisateurs à consulter les politiques de confidentialité de ces sites tiers.
      </p>

      <h2>Changements de la Politique de confidentialité</h2>
      <p>
        Précisez que vous vous réservez le droit de modifier votre Politique de confidentialité à tout moment. Indiquez comment vous informerez les utilisateurs des changements et à partir de quand les modifications seront effectives.
      </p>

      <h2>Coordonnées</h2>
      <p>
        Fournissez des coordonnées de contact pour que les utilisateurs puissent vous contacter s'ils ont des questions ou des préoccupations concernant la confidentialité de leurs données.
      </p>

      Politique de cookies: Si vous n'avez pas déjà inclus cette information dans votre Politique de confidentialité, vous pouvez créer une section dédiée à la politique de cookies. Expliquez comment vous utilisez les cookies sur votre site, quels types de cookies vous utilisez, et comment les utilisateurs peuvent gérer leurs préférences en matière de cookies.

      Protection des données : Si vous ne l'avez pas déjà inclus dans votre Politique de confidentialité, vous pouvez ajouter une section détaillant vos pratiques de protection des données, y compris les mesures de sécurité mises en place pour protéger les informations personnelles des utilisateurs.

    </div>
  </template>
  
  <script>
  export default {
    name: 'PolitiqueConfidentialite',
    // Le reste de votre logique JavaScript
  }
  </script>
  
  <style scoped>
  .container{
    margin: 3rem;
    padding: 2rem;
    text-align: justify;
  }
  </style>